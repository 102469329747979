import React, { useState } from 'react';
import './register.css';
import { Helmet } from 'react-helmet';
import Navbar from '../components/navbar';
import Footer from '../components/footer';
import ReCAPTCHA from "react-google-recaptcha";
import axios from "axios";
import { globalConstants } from '../components/constants';




const Register = () => {
    const recaptchaRef = React.createRef();
    const [isloading, setIsLoading] = useState(false);
    const [error, setError] = useState('');
    const [formData, setFormData] = useState({
        email: '',
        password: '',
        confirmPassword: '',
        recatpcha: ''
    });

    const isStrongPassword = (password) => {
        const strongPasswordRegex = /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]{8,}$/;
        return strongPasswordRegex.test(password);
    };

    const handleChange = (e) => {
        setError('');
        const { name, value } = e.target;
        setFormData({
            ...formData,
            [name]: value
        });
    };

    const handleSubmit = (e) => {
        e.preventDefault();
        if(formData.password !== formData.confirmPassword){
            setError('Las contraseñas no coinciden');
            return;
        }
        else if(!isStrongPassword(formData.password)){
            setError('La contraseña debe tener al menos 8 caracteres, una letra mayúscula, una letra minúscula, un número y un carácter especial');
            return;
        }

        setIsLoading(true);
        recaptchaRef.current.execute();
       
        
        const api = axios.create({
            baseURL: globalConstants.API,
            headers: {
                "Content-Type": "application/json"
            },
        });
        api.post("api/SignUp", formData).then((response) => {    
            setIsLoading(false);
            window.location.href = "/verify?email="+formData.email;             
        }   
        ).catch((error) => {
            setIsLoading(false);
            if(error.status==402){
                alert("El usuario ya existe, por favor inicie sesión");
                window.location.href = "/Login";         
            }
            else if(error.status==401){                
                window.location.href = "/verify?email="+formData.email;             
            }
            else
                alert("Error al registrar el usuario, verifique su información e intente de nuevo");
            console.log(error); 
        });            
        // Add form submission logic here
        console.log('Form submitted', formData);
    };

    return (
        <div className="body-contain">
              <Helmet>
                  <title>Mi Vigilancia Registrar nuevo usuario</title>
                 </Helmet>
      <Navbar
        link1="Inicio"
        link2="Acerca"
        link3="Servicios"
        link4="Precios"
        link5="Contacto"
        action1="Register"
        action2="Login"
      ></Navbar>
            <h2>Crea tu cuenta</h2>
            <form onSubmit={handleSubmit}>
            
                <div>
                
                    <label>Email:</label>
                    <input
                        type="email"
                        name="email"
                        value={formData.email}
                        onChange={handleChange}
                        required
                    />
                </div>
                <div>
                    <label>Contraseña:</label>
                    
                    <input
                        type="password"
                        name="password"
                        value={formData.password}
                        onChange={handleChange}
                        required
                    />
                                       
                </div>
                { error &&<label className='pwd-conditions'>
                    {error}
                    </label>} 
                <div>
                    <label>Confirma tu contraseña:</label>
                    <input
                        type="password"
                        name="confirmPassword"
                        value={formData.confirmPassword}
                        onChange={handleChange}
                        required
                    />
                </div>
                <div className='register-terms'>
                    <label > Al crear una cuenta, aceptas nuestras <a href="/condiciones" > Condiciones </a> y declaras haber leído y 
                    estar de acuerdo con la <a href="/privacidad" >Declaración de privacidad global</a>.</label>
                
                </div>
                <ReCAPTCHA ref={recaptchaRef} size="invisible" sitekey="6LcET8gqAAAAAIgtZHdNdDuTsuJsxlMFVfgtN_gW"  onChange={handleChange}  />
                
                <button className='register-button' type="submit" disabled={isloading} >
                {isloading ? 'Registrando...' : 'Registra tu cuenta'}
                </button>
                <div className='register-terms'>
                    <input
                        type="checkbox"
                        id="terms"
                        name="terms"
                        value={formData.terms} 
                    />
                    <label htmlFor="terms" > Acepto recibir correos electrónicos sobre MiVigilancia, actualizaciones de productos y promociones de MiVigilancia.                      
                    </label>
                </div>
            </form>
             <Footer
                    privacyLink="Politica de Privacidad"
                    termsLink="Terminos de Servicio"
                    cookiesLink="Cookies"
                    socialLinkTitleCategory="Siguenos"
                    link1="Servicios"
                    link2="Precios"
                    link3="Registrate"
                    link4="Contactanos"
                  ></Footer>
        </div>
    );
};

export default Register;