import React from 'react'

import PropTypes from 'prop-types'

import './contact.css'

const Contact = (props) => {
  return (
    <div id="contacto" className="contact-contact20 thq-section-padding">
      <div className="contact-max-width thq-section-max-width">
        <div className="contact-section-title">
          <span className="thq-body-small">{props.content2}</span>
          <div className="contact-content1">
            <h2 className="thq-heading-2">{props.heading1}</h2>
            <p className="contact-text3 thq-body-large">{props.content1}</p>
          </div>
        </div>
        <div className="contact-row">
          <div className="contact-content2">
            <svg viewBox="0 0 1024 1024" className="thq-icon-medium">
              <path d="M854 342v-86l-342 214-342-214v86l342 212zM854 170q34 0 59 26t25 60v512q0 34-25 60t-59 26h-684q-34 0-59-26t-25-60v-512q0-34 25-60t59-26h684z"></path>
            </svg>
            <div className="contact-contact-info">
              <div className="contact-content3">
                <h3 className="contact-text4 thq-heading-3">Email</h3>
                <p className="contact-text5 thq-body-large">{props.content3}</p>
              </div>
              <span className="contact-email thq-body-small">
              <a href={`mailto:${props.email1}`}>
                {props.email1}</a>
              </span>
            </div>
          </div>
          <div className="contact-content4"></div>
        </div>
      </div>
    </div>
  )
}

Contact.defaultProps = {
  content1: 'Envianos tus dudas o preguntas y a la brevedad uno de nuestros asesores te contactara. ',
  email1: 'ventas@mivigilancia.com',
  heading1: 'Contactanos',
  content2: 'Tienes dudas o preguntas estamos listos para escucharte!',
  content3:
    'Nuestro equpo estas listo para atenderte.',
}

Contact.propTypes = {
  content1: PropTypes.string,
  email1: PropTypes.string,
  heading1: PropTypes.string,
  content2: PropTypes.string,
  content3: PropTypes.string,
}

export default Contact
