import React, { useState } from 'react';
import './condiciones.css';
import Navbar from '../components/navbar';
import { Helmet } from 'react-helmet';
import Footer from '../components/footer';

const condicionesPage = () => {
  
    return (
        <div className='body-contain'>
             <Helmet>
                  <title>Terminos y condiciones Mi Vigilancia</title>
                 </Helmet>
      <Navbar
        link1="Inicio"
        link2="Acerca"
        link3="Servicios"
        link4="Precios"
        link5="Contacto"
        action1="Register"
        action2="Login"
      ></Navbar>
            
            <div className='container'>
            <h1> Términos y Condiciones de "Mi Vigilancia"</h1>
            
            <h2>1. Introducción</h2>
<p>
Bienvenido a "Mi Vigilancia", un sistema de gestión de guardias (en adelante, "el Servicio") proporcionado por [Tu Empresa] (en adelante, "la Empresa", "nosotros" o "nos"). Al acceder o utilizar el Servicio, usted (en adelante, "el Usuario" o "usted") acepta estos Términos y Condiciones (en adelante, "los Términos"), que constituyen un acuerdo legal entre usted y la Empresa.
</p>
<h2>2. Descripción del Servicio</h2>
"Mi Vigilancia" es un sistema que permite a empresas y organizaciones gestionar de manera eficiente a sus guardias de seguridad. El Servicio incluye, pero no se limita a:
<p>
Gestión de horarios y turnos de guardia.
Seguimiento de asistencia y puntualidad.
Comunicación y coordinación entre guardias y supervisores.
Generación de informes y estadísticas.
[Agregar otras funcionalidades específicas de tu sistema]
</p>
<h2>3. Aceptación de los Términos</h2>
<p>
Al acceder o utilizar el Servicio, usted declara y garantiza que:

Es mayor de edad y tiene la capacidad legal para celebrar este acuerdo.
Ha leído, comprendido y acepta estos Términos en su totalidad.
Si utiliza el Servicio en nombre de una empresa u organización, tiene la autoridad para aceptar estos Términos en nombre de dicha entidad.
</p>
<h2>4. Registro y Cuenta de Usuario</h2> 
<p>
Para acceder a ciertas funciones del Servicio, es posible que deba registrarse y crear una cuenta de usuario. Usted es responsable de mantener la confidencialidad de su contraseña y de toda la actividad que ocurra en su cuenta. Usted acepta notificarnos de inmediato cualquier uso no autorizado de su cuenta.   
</p>
<h2>5. Uso del Servicio</h2>
<p>
Usted acepta utilizar el Servicio de conformidad con estos Términos y todas las leyes y regulaciones aplicables. Usted no podrá:

Utilizar el Servicio para fines ilegales o no autorizados.
Interferir o interrumpir el funcionamiento del Servicio.
Intentar acceder a áreas no autorizadas del Servicio.
Utilizar el Servicio para enviar spam o comunicaciones no solicitadas.
[Agregar otras restricciones específicas de tu sistema]
</p>
<h2>6. Propiedad Intelectual</h2>
<p>
Todos los derechos de propiedad intelectual relacionados con el Servicio, incluyendo software, diseño, logotipos y contenido, son propiedad exclusiva de la Empresa. Usted no adquiere ningún derecho sobre la propiedad intelectual al utilizar el Servicio.
</p>
<h2>7. Privacidad</h2>
<p>
La recopilación y el uso de su información personal se rigen por nuestra Política de Privacidad, que forma parte integral de estos Términos.
</p>
<h2>8. Limitación de Responsabilidad</h2>
<p>
En la máxima medida permitida por la ley, la Empresa no será responsable de ningún daño directo, indirecto, incidental, especial o consecuencial que surja del uso o la imposibilidad de utilizar el Servicio.   
</p>
<h2>9. Modificación de los Términos</h2>
<p>
Nos reservamos el derecho de modificar estos Términos en cualquier momento. Le notificaremos sobre los cambios publicando los Términos actualizados en el Servicio. Su uso continuado del Servicio después de la publicación de los cambios constituye su aceptación de los Términos modificados.
</p>
<h2>10. Terminación</h2>
<p>
Podemos terminar su acceso al Servicio en cualquier momento, con o sin causa, con o sin previo aviso. Usted puede cancelar su cuenta en cualquier momento.
</p>
<h2>11. Ley Aplicable</h2>
<p>
Estos Términos se rigen por las leyes de Mexico.
</p>
<h2>12. Contacto</h2>
<p>
Si tiene alguna pregunta sobre estos Términos, puede contactarnos a través de soporte@mivigilancia.com.
</p>

<h2>Consideraciones Adicionales</h2>
<p>
Pagos: Si tu sistema incluye funcionalidades de pago, asegúrate de detallar los términos de pago, incluyendo precios, métodos de pago, reembolsos, etc.
Garantías: Si ofreces alguna garantía sobre el servicio, especifícala claramente en los términos.
Disputas: Incluye una sección que detalle cómo se resolverán las disputas entre los usuarios y la empresa.
</p>
            </div>
             <Footer
                                privacyLink="Politica de Privacidad"
                                termsLink="Terminos de Servicio"
                                cookiesLink="Cookies"
                                socialLinkTitleCategory="Siguenos"
                                link1="Servicios"
                                link2="Precios"
                                link3="Registrate"
                                link4="Contactanos"
                              ></Footer>
        </div>
    );
};

export default condicionesPage;
