import React, { useState } from 'react';
import './login.css';
import Navbar from '../components/navbar';
import { Helmet } from 'react-helmet';
import Footer from '../components/footer';
import { globalConstants } from '../components/constants';
import axios from 'axios';
import { useAuth } from '../AuthContext';


const LoginForm = () => {
    const [error, setError] = useState('');
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const [isLoading, setIsLoading] = useState(false);
    const { login,state,isAuthenticated  } = useAuth();
    
    const handleSubmit = (e) => {
        e.preventDefault();
        setError('');                   
        setIsLoading(true);
       
        const api = axios.create({
            baseURL: globalConstants.API,
            headers: {
                "Content-Type": "application/json"
            },
        });
        api.post("api/Login", {
            email: email,
            password: password
        }).then(async (response) => { 
            setIsLoading(false); 
            
            var token=await response.data.message;
            console.log(token);
            login(token);
            if(response.status=202)
                window.location.href = "/setup";                
            else
                window.location.href = "/dashboard";
        }    
        ).catch((ex) => {
            setIsLoading(false); 
            console.log(ex);
            if(ex?.response?.data)
                setError(ex?.response?.data?.message);                                     
            else
                setError('Error al iniciar sesion, intente de nuevo');
            
        });            
    };

    return (
        <div className='body-contain'>
             <Helmet>
                  <title>Mi Vigilancia Inicia Sesion</title>
                 </Helmet>
      <Navbar
        link1="Inicio"
        link2="Acerca"
        link3="Servicios"
        link4="Precios"
        link5="Contacto"
        action1="Register"
        action2="Login"
      ></Navbar>
      <div className="login-container">
            <h2>Inicia Sesion </h2>
            <form onSubmit={handleSubmit}>
                <div>
                    <label>Email:</label>
                    <input
                        type="email"
                        value={email}
                        onChange={(e) => {setEmail(e.target.value);setError('')}}
                        required
                    />
                </div>
                <div>
                    <label>Contraseña:</label>
                    <input
                        type="password"
                        value={password}
                        onChange={(e) => {setPassword(e.target.value);setError('')}}
                        required
                    />
                </div>
                <label className='error'>{error}</label>                
            <button type="submit" disabled={isLoading}>
                {isLoading ? 'Cargando...' : 'Siguiente'}
            </button>
            </form>
            </div>
             <Footer
                                privacyLink="Politica de Privacidad"
                                termsLink="Terminos de Servicio"
                                cookiesLink="Cookies"
                                socialLinkTitleCategory="Siguenos"
                                link1="Servicios"
                                link2="Precios"
                                link3="Registrate"
                                link4="Contactanos"
                              ></Footer>
        </div>
    );
};

export default LoginForm;
