import React, { useState } from 'react';
import './dashboard.css';

import { Helmet } from 'react-helmet';
import Footer from '../components/footer';
import NavbarAuth from '../components/navVarAuth';

const dashboardPage = () => {
  
    return (
        <div className='body-contain'>
             <Helmet><title>Bienvenido a Mi Vigilancia</title></Helmet>
            <NavbarAuth></NavbarAuth>
            
            <div className='container'>
                <h1> DASHBOARD</h1>                      
            </div>         
        </div>
    );
};

export default dashboardPage;
