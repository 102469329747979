import axios from 'axios';
import React, { createContext, useState, useContext, useEffect, useMemo } from 'react';
import { decodeToken } from "react-jwt";

const AuthContext = createContext();
const AuthProvider = ({ children }) => {
 const [token, setToken_] = useState(localStorage.getItem("token"));
  const [isAuthenticated, setIsAuthenticated] = useState(sessionStorage.getItem('user')?true:false);

  const setToken = (newToken) => {
    setToken_(newToken);
  };

  const login = (_token) => {
    // Implement your login logic here
    console.log("MYTOEK",_token);
    var decodedToken  = decodeToken(_token);    
    setToken(_token);
    sessionStorage.setItem('user',decodedToken.userID);
    setIsAuthenticated(true);
  };
  const logout = () => {
    // Implement your logout logic here
    setToken();
    sessionStorage.removeItem('user');
    setIsAuthenticated(false);
  };

  useEffect(() => {
    if (token) {        
      axios.defaults.headers.common["Authorization"] = "Bearer " + token;
      localStorage.setItem('token',token);
    } else {
      delete axios.defaults.headers.common["Authorization"];
      localStorage.removeItem('token')
    }
  }, [token]);

  const contextValue = useMemo(
    () => ({
      token,
      setToken,
    }),
    [token]
  );

  return (
    <AuthContext.Provider value={{ isAuthenticated, login, logout }}>
      {children}
    </AuthContext.Provider>
  );
};



const useAuth = () => {
  const context = useContext(AuthContext); 
  if (!context) {
    throw new Error('useAuth must be used within an AuthProvider');
  }
  return context;
};
export { AuthProvider, useAuth };