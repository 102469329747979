import React, { Fragment } from 'react'

import PropTypes from 'prop-types'

import './steps.css'

const Steps = (props) => {
  return (
    <div id="steps" className="steps-container1 thq-section-padding">
      <div className="steps-max-width thq-section-max-width">
        <div className="steps-container2 thq-grid-2">
          <div className="steps-section-header">
            <h2 className="thq-heading-2">🔒 Control Total, Mayor Seguridad</h2>
            <p className="thq-body-large">
              <span>
                Desde la gestión de personal hasta la supervisión de
                operaciones, nuestra plataforma te permite administrar cada
                detalle de tu equipo de seguridad en un solo lugar.
              </span>
              <br></br>
              <br></br>
              <span>✅ Registro de empleados y turnos</span>
              <br></br>
              <span>✅ Supervisión de rondas y bitácoras</span>
              <br></br>
              <span>✅ Reportes detallados y alertas automáticas</span>
              <br></br>
              <br></br>
              <span>🔹 Tu seguridad, bajo control.</span>
            </p>
            <div className="steps-actions">
              <a
                href="https://www.mivigilancia.com/register"
                className="steps-button thq-button-filled thq-button-animated"
              >
                <span className="thq-body-small">Registrate</span>
              </a>
            </div>
          </div>
          <div className="steps-container3">
            <div className="steps-container4 thq-card">
              <h2 className="thq-heading-2">{props.step1Title}</h2>
              <span className="steps-text25 thq-body-small">
                {props.text ?? (
                  <Fragment>
                    <span className="steps-text29"></span>
                  </Fragment>
                )}
              </span>
            </div>
            <div className="steps-container5 thq-card">
              <h2 className="thq-heading-2">{props.step2Title}</h2>
            </div>
            <div className="steps-container6 thq-card">
              <h2 className="thq-heading-2">{props.step3Title}</h2>
            </div>
            <div className="steps-container7 thq-card">
              <h2 className="thq-heading-2">{props.step4Title}</h2>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

Steps.defaultProps = {
  step4Title: '📌 Gestionar incidentes y bitácoras',
  step3Title: '📝 Historial detallado de eventos y actividades',
  step2Title: '📊 Informes y Alertas en Tiempo Real',
  step1Title: '📋 Mantén el Control de Todas las Operaciones',
  text: undefined,
}

Steps.propTypes = {
  step4Title: PropTypes.string,
  step3Title: PropTypes.string,
  step2Title: PropTypes.string,
  step1Title: PropTypes.string,
  text: PropTypes.element,
}

export default Steps
