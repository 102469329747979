import React from 'react'
import { HashLink as Link } from 'react-router-hash-link';

import PropTypes from 'prop-types'

import './navbar.css'
import { useAuth } from '../AuthContext';

const NavbarAuth = (props) => {

 const {logout} = useAuth();

  return (
    <header className="navbar-container">
      <header data-thq="thq-navbar" className="navbar-navbar-interactive">
        <img
          alt={props.logoAlt}
          src={props.logoSrc}
          className="navbar-image1"
        />
         <label className='logo-name'>&nbsp;&nbsp;Mi Vigilancia</label>
        <div data-thq="thq-navbar-nav" className="navbar-desktop-menu">
          <nav className="navbar-links1">
            <Link to="/profile" className="navbar-link11 thq-body-small thq-link">
              Profile
            </Link> 
            <Link to="/Dasbhoard" className="navbar-link11 thq-body-small thq-link">
              Dashboard
            </Link>            
          </nav>
          <div className="navbar-buttons1">
            <button className="navbar-action21 thq-button-outline thq-button-animated" onClick={()=>logout()}>
              <span className="thq-body-small">
                <span>Cerrar Sesion</span>
                <br></br>
              </span>
            </button>
          </div>
        </div>
        <div data-thq="thq-burger-menu" className="navbar-burger-menu">
          <svg viewBox="0 0 1024 1024" className="navbar-icon1">
            <path d="M128 554.667h768c23.552 0 42.667-19.115 42.667-42.667s-19.115-42.667-42.667-42.667h-768c-23.552 0-42.667 19.115-42.667 42.667s19.115 42.667 42.667 42.667zM128 298.667h768c23.552 0 42.667-19.115 42.667-42.667s-19.115-42.667-42.667-42.667h-768c-23.552 0-42.667 19.115-42.667 42.667s19.115 42.667 42.667 42.667zM128 810.667h768c23.552 0 42.667-19.115 42.667-42.667s-19.115-42.667-42.667-42.667h-768c-23.552 0-42.667 19.115-42.667 42.667s19.115 42.667 42.667 42.667z"></path>
          </svg>
        </div>
        <div data-thq="thq-mobile-menu" className="navbar-mobile-menu">
          <div className="navbar-nav">
            <div className="navbar-top">
              <img
                alt={props.logoAlt}
                src={props.logoSrc}
                className="navbar-logo"
              />
              <label className='logo-name'> Mi Vigilancia</label>
              
              <div data-thq="thq-close-menu" className="navbar-close-menu">
              
                <svg viewBox="0 0 1024 1024" className="navbar-icon3">
                  <path d="M810 274l-238 238 238 238-60 60-238-238-238 238-60-60 238-238-238-238 60-60 238 238 238-238z"></path>
                </svg>
              </div>
            </div>
            <nav className="navbar-links2">
            <Link to="/#inicio" className="navbar-link11 thq-body-small thq-link">
              Profile
            </Link> 
            <Link to="/#steps" className="navbar-link11 thq-body-small thq-link">
              Dashboard
            </Link>          
            </nav>
          </div>
          <div className="navbar-buttons2">
            <button className="thq-button-filled" onClick={()=>logout()}>Cerrar Sesion</button>
          </div>
        </div>
      </header>
    </header>
  )
}

NavbarAuth.defaultProps = {
  action2: 'Action 2',
  link2: 'Link2',
  action1: 'Action 1',
  link1: 'Inicio',
  link4: 'Link4',
  link5: 'Link5',
  logoAlt: 'logo',
  link3: 'Link3',
  logoSrc: '/iconguard-200h.png',
}

NavbarAuth.propTypes = {
  action2: PropTypes.string,
  link2: PropTypes.string,
  action1: PropTypes.string,
  link1: PropTypes.string,
  link4: PropTypes.string,
  link5: PropTypes.string,
  logoAlt: PropTypes.string,
  link3: PropTypes.string,
  logoSrc: PropTypes.string,
}

export default NavbarAuth
