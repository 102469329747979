import React, { Fragment } from 'react'

import { Helmet } from 'react-helmet'

import Navbar from '../components/navbar'
import Hero from '../components/hero'
import Features1 from '../components/features1'
import CTA from '../components/cta'
import Features2 from '../components/features2'
import Pricing from '../components/pricing'
import Steps from '../components/steps'
import Testimonial from '../components/testimonial'
import Contact from '../components/contact'
import Footer from '../components/footer'
import './home.css'

const Home = (props) => {
  return (
    <div className="home-container">
      <Helmet>
        <title>Spotless Hungry Crocodile</title>
      </Helmet>
      <Navbar
        link1="Inicio"
        link2="Acerca"
        link3="Servicios"
        link4="Precios"
        link5="Contacto"
        action1="Register"
        action2="Login"
      ></Navbar>
      <Hero
        heading1="Sistema de Gestion de Guardias"
        text={
          <Fragment>
            <span className="home-text100">
              <span>
                Optimiza la gestión de tu equipo de seguridad con una sola
                plataforma
              </span>
              <br></br>
              <br></br>
              <span>
                Centraliza el control y la administración de tu personal de
                seguridad con una solución todo-en-uno. Gestiona empleados,
                horarios de rotación, capacitación y control de tiempo, además
                de operaciones clave como acceso, paquetes, incidentes,
                bitácoras y rondas. Recibe alertas en tiempo real y genera
                informes detallados para optimizar el rendimiento y garantizar
                un servicio impecable.
              </span>
              <div className="home-list">
              <span>✅ Registro de empleados y turnos</span> <br></br>
              <span>✅ Control de Accesos</span> <br></br>
              <span>✅ Bitacora digital </span> <br></br>
              <span>✅ Control de Incidentes </span> <br></br>
              <span>✅ Gestion de Paquetes</span> <br></br>
              <span>✅ Gestion de Rondines</span> <br></br>
              <span>✅ Cacpcitaciones y tareas</span>
              </div>
            </span>
          </Fragment>
        }
        image1Src="/img/acceso1.jpg"
        image2Src="/img/acceso2.jpg"
        image3Src="/img/acceso3.jpg"
        image7Src="https://images.unsplash.com/photo-1513880365980-7159ec1cba3a?crop=entropy&amp;cs=tinysrgb&amp;fit=max&amp;fm=jpg&amp;ixid=M3w5MTMyMXwwfDF8cmFuZG9tfHx8fHx8fHx8MTczODI1NTEzMXw&amp;ixlib=rb-4.0.3&amp;q=80&amp;w=1080"
        image8Src="https://images.unsplash.com/photo-1566245024852-04fbf7842ce9?crop=entropy&amp;cs=tinysrgb&amp;fit=max&amp;fm=jpg&amp;ixid=M3w5MTMyMXwwfDF8cmFuZG9tfHx8fHx8fHx8MTczODI1NTE0MXw&amp;ixlib=rb-4.0.3&amp;q=80&amp;w=1080"
        image9Src="https://images.unsplash.com/photo-1581094794329-c8112a89af12?crop=entropy&amp;cs=tinysrgb&amp;fit=max&amp;fm=jpg&amp;ixid=M3w5MTMyMXwwfDF8cmFuZG9tfHx8fHx8fHx8MTczODI1NTE1NHw&amp;ixlib=rb-4.0.3&amp;q=80&amp;w=1080"
        action1="Registrate"
        action2="Inicia Sesion"
      ></Hero>
      <Features1
        feature1ImgSrc="https://images.unsplash.com/photo-1538115081112-32c7d8401807?crop=entropy&amp;cs=tinysrgb&amp;fit=max&amp;fm=jpg&amp;ixid=M3w5MTMyMXwwfDF8cmFuZG9tfHx8fHx8fHx8MTczODI1NTIwNXw&amp;ixlib=rb-4.0.3&amp;q=80&amp;w=1080"
        feature1Title="📋 Mantén el Control de Todas las Operaciones"
        feature1Description1={
          <Fragment>
            <span className="home-text105">
              <span>
                🚀 Olvídate del papeleo y la desorganización. Con nuestra
                plataforma puedes:
              </span>
              <br></br>
              <br></br>
              <span>📌 Optimizar horarios y rotaciones</span>
              <br></br>
              <span>📌 Controlar accesos y paquetes</span>
              <br></br>
              <span>📌 Gestionar incidentes y bitácoras</span>
              <br></br>
              <span>📌 Supervisar el desempeño del personal</span>
              <br></br>
              <br></br>
              <span>
                🔹 Toma decisiones más inteligentes con datos en tiempo real.
              </span>
              <br></br>
              <br></br>
              Lorem ipsum dolor sit amet, consectetur adipiscing elit.
              Suspendisse varius enim in eros elementum tristique. Duis cursus,
              mi quis viverra ornare, eros dolor interdum nulla, ut commodo diam
              libero vitae erat.
            </span>
          </Fragment>
        }
        feature2Title="🔒 Control Total, Mayor Seguridad"
        feature2Description1={
          <Fragment>
            <span className="home-text121">
              <span>
                Desde la gestión de personal hasta la supervisión de
                operaciones, nuestra plataforma te permite administrar cada
                detalle de tu equipo de seguridad en un solo lugar.
              </span>
              <br></br>
              <br></br>
              <span>✅ Registro de empleados y turnos</span>
              <br></br>
              <span>✅ Supervisión de rondas y bitácoras</span>
              <br></br>
              <span>✅ Reportes detallados y alertas automáticas</span>
              <br></br>
              <br></br>
              <span>🔹 Tu seguridad, bajo control.</span>
              Lorem ipsum dolor sit amet, consectetur adipiscing elit.
              Suspendisse varius enim in eros elementum tristique. Duis cursus,
              mi quis viverra ornare, eros dolor interdum nulla, ut commodo diam
              libero vitae erat.
            </span>
          </Fragment>
        }
        feature3Title="📊 Informes y Alertas en Tiempo Real"
        feature3Description1={
          <Fragment>
            <span className="home-text133">
              <span>
                💡 ¿Qué pasa si algo no va según lo planeado? Nuestra plataforma
                te avisa al instante.
              </span>
              <br></br>
              <br></br>
              <span>🔔 Notificaciones inmediatas de irregularidades</span>
              <br></br>
              <span>📈 Análisis de desempeño del personal</span>
              <br></br>
              <span>📝 Historial detallado de eventos y actividades</span>
              <br></br>
              <br></br>
              <span>
                🔹 Optimiza la seguridad con información precisa y en el momento
                justo.
              </span>
              Lorem ipsum dolor sit amet, consectetur adipiscing elit.
              Suspendisse varius enim in eros elementum tristique. Duis cursus,
              mi quis viverra ornare, eros dolor interdum nulla, ut commodo diam
              libero vitae erat.
            </span>
          </Fragment>
        }
      ></Features1>
      <CTA
        action1="Registrate"
        text={
          <Fragment>
            <span className="home-text145">
              <span>
                Simplifica la gestión de tu equipo con una plataforma
                todo-en-uno.
              </span>
              <br></br>
              <span>
                Administra empleados, turnos, capacitación, acceso, incidentes y
                más, con reportes y alertas en tiempo real.
              </span>
              <br></br>
              <br></br>
              <span>
                📌 Automatiza procesos | 📊 Monitorea el rendimiento | 🚨 Recibe
                alertas inmediatas
              </span>
              <br></br>
              <br></br>
              <span>🔹 ¡Descubre cómo mejorar tu operación!</span>
              Lorem ipsum dolor sit amet, consectetur adipiscing elit.
              Suspendisse varius enim in eros elementum tristique.
            </span>
          </Fragment>
        }
        heading1="🏢 Solución Integral para Empresas de Seguridad"
      ></CTA>
      <Features2
        feature1Title="👮‍♂️ Gestión Eficiente de Guardias y Seguridad"
        feature1Description1={
          <Fragment>
            <span className="home-text155">
              <span>
                Administra a tu equipo con roles personalizados y control total
              </span>
              <br></br>
              <br></br>
              <span>
                📌 Usuarios con roles específicos: Administrador, Supervisor y
                Guardia
              </span>
              <br></br>
              <span>
                📌 Registro y gestión de guardias con ubicación, disponibilidad
                y habilidades
              </span>
              <br></br>
              <span>
                📌 Rotación de turnos, tareas y capacitaciones automatizadas
              </span>
              <br></br>
              <br></br>
              <span>
                🔹 Optimiza la organización y asegura un servicio impecable.
              </span>
            </span>
          </Fragment>
        }
        feature2Title="📱Acceso Exclusivo "
        feature2Description1={
          <Fragment>
            <span className="home-text167">
              <span>Tu seguridad, en la palma de tu mano.</span>
              <br></br>
              <br></br>
              <span>🔹 Tus datos protegidos y seguros en la nube</span>
              <br></br>
              <span>🔹 Renovación automática del soporte con pago seguro</span>
              <br></br>
              <br></br>
              <span>
                ✅ Transparencia, control y tranquilidad para tu empresa.
              </span>
            </span>
          </Fragment>
        }
        feature3Title="📅 Control de Horarios y Tareas en un Solo Lugar"
        feature3Description1={
          <Fragment>
            <span className="home-text177">
              <span>
                📢 Mantén el orden en las operaciones con una gestión eficiente
                de horarios y asignaciones.
              </span>
              <br></br>
              <br></br>
              <span>✔️ Crea y administra rotaciones de turnos</span>
              <br></br>
              <span>
                ✔️ Asigna tareas y recibe actualizaciones en tiempo real
              </span>
              <br></br>
              <span>
                ✔️ Guías y artículos esenciales disponibles para guardias
              </span>
              <br></br>
              <br></br>
              <span>
                🔹 Mejora la productividad y evita confusiones en la asignación
                de actividades.
              </span>
              Lorem ipsum dolor sit amet, consectetur adipiscing elit.
              Suspendisse varius enim in eros elementum tristique. Duis cursus,
              mi quis viverra ornare, eros dolor interdum nulla, ut commodo diam
              libero vitae erat.
            </span>
          </Fragment>
        }
      ></Features2>
      <Pricing
        content1="Elige el plan que se adecua a tus necesidades"
        heading1="Planes "
        content2="Con nuestro planes puedes obtener la mejor aplicacion sin tener que invertir en costos excesivos\n"
        plan1Price="$359/mo"
        plan1Yearly="10% descuento en plan anual"
        plan2Price="$1,099/mo"
        plan2Yearly="12% descuento en plan anual"
        plan3Price="$2,199/mo"
        plan3Yearly="15% descuento en plan anual"
        plan1Feature1=" 10 Usuarios"
        plan1Feature2="3 Servicios (Residenciales, inmuebles, intramuros)"
        plan1Feature3=" 500 Propiedades"
        plan2Feature1=" 50 Usuarios"
        plan2Feature2=" 30 Servicios"
        plan2Feature3=" 5000 Propiedades"
        plan2Feature4="Guardar registros por 1 mes"
        plan1Action="Registrate"
        plan2Action="Registrate"
        plan3Action="Registrate"
        plan3Feature1="150 Usuarios"
        plan3Feature2="100 Servicios"
        plan3Feature3="15,000 Propiedades"
        plan3Feature4="Historial de Registros  de 3 Meses\n"
        plan3Feature5="Feature text goes here"
      ></Pricing>
      <Steps
        text={
          <Fragment>
            <span className="home-text189"></span>
          </Fragment>
        }
        step1Title="📋 Mantén el Control de Todas las Operaciones"
      ></Steps>
      <Testimonial
        heading1="Testimonios"
        content1="Nuestra experiencia nos respalda Nuestros clientes"
        author1Name="Pablo"
        author1Position="Director, SIAC Mexico"
        text={
          <Fragment>
            <span className="home-text190">
              <span>
                ⭐⭐⭐⭐⭐ &quot;Transformó nuestra operación de seguridad&quot;
              </span>
              <br></br>
              <span>
                &quot;Desde que implementamos esta plataforma, la gestión de
                turnos y la comunicación con nuestros guardias ha sido mucho más
                eficiente. Ahora recibimos alertas en tiempo real y podemos
                actuar de inmediato ante cualquier incidente.&quot;
              </span>
              Lorem ipsum dolor sit amet, consectetur adipiscing elit.
              Suspendisse varius enim in eros elementum tristique. Duis cursus,
              mi quis viverra ornare, eros dolor interdum nulla.
            </span>
          </Fragment>
        }
        text1={
          <Fragment>
            <span className="home-text194">
              <span>⭐⭐⭐⭐⭐ &quot;Mayor control y transparencia&quot;</span>
              <br></br>
              <span>
                &quot;El sistema nos permite supervisar cada aspecto del
                servicio, desde el acceso hasta la bitácora de eventos. Ahora
                tenemos reportes detallados del desempeño de nuestros guardias y
                podemos mejorar continuamente nuestra operación.&quot;
              </span>
              <br></br>
              <span>— Ana R., Administradora de Seguridad</span>
              Lorem ipsum dolor sit amet, consectetur adipiscing elit.
              Suspendisse varius enim in eros elementum tristique. Duis cursus,
              mi quis viverra ornare, eros dolor interdum nulla.
            </span>
          </Fragment>
        }
        text2={
          <Fragment>
            <span className="home-text200">
              <span>
                ⭐⭐⭐⭐⭐ &quot;Facilita la gestión y mejora la
                productividad&quot;
              </span>
              <br></br>
              <span>
                &quot;Antes teníamos problemas con la asignación de turnos y
                tareas. Ahora todo está automatizado y cada guardia sabe
                exactamente qué hacer. Además, la APP Mi Vigilancia nos ha
                permitido mejorar la comunicación con nuestros clientes.&quot;
              </span>
              Lorem ipsum dolor sit amet, consectetur adipiscing elit.
              Suspendisse varius enim in eros elementum tristique. Duis cursus,
              mi quis viverra ornare, eros dolor interdum nulla.
            </span>
          </Fragment>
        }
        text3={
          <Fragment>
            <span className="home-text204">
              <span>
                ⭐⭐⭐⭐⭐ &quot;La mejor inversión en seguridad&quot;
              </span>
              <br></br>
              <span>
                &quot;No solo optimizamos nuestros procesos internos, sino que
                también ofrecemos un mejor servicio a nuestros clientes. El
                monitore y control nos ha ayudado a reducir mucho tiempo y
                esfuerzo.&quot;
              </span>
              Lorem ipsum dolor sit amet, consectetur adipiscing elit.
              Suspendisse varius enim in eros elementum tristique. Duis cursus,
              mi quis viverra ornare, eros dolor interdum nulla.
            </span>
          </Fragment>
        }
      ></Testimonial>
      <Contact
      ></Contact>
      <Footer
        privacyLink="Politica de Privacidad"
        termsLink="Terminos de Servicio"
        cookiesLink="Cookies"
        socialLinkTitleCategory="Siguenos"
        link1="Servicios"
        link2="Precios"
        link3="Registrate"
        link4="Contactanos"
      ></Footer>
    </div>
  )
}

export default Home
