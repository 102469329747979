import React, { useState } from 'react';
import './condiciones.css';
import Navbar from '../components/navbar';
import { Helmet } from 'react-helmet';
import Footer from '../components/footer';
const privacidadPage = () => {
  
    return (
      <div className='body-contain'>
       <Helmet>
        <title>Aviso de Privacidad Mi Vigilancia</title>
       </Helmet>
       <Navbar
        link1="Inicio"
        link2="Acerca"
        link3="Servicios"
        link4="Precios"
        link5="Contacto"
        action1="Register"
        action2="Login"
       ></Navbar>
      
      <div className='container'>
      <h1>AVISO DE PRIVACIDAD INTEGRAL "Mi Vigilancia"</h1>
          <p>I. LA IDENTIDAD Y DOMICILIO DEL RESPONSABLE QUE LOS RECABA.</p>
          <p>MiVigilancia, en adelante (“Responsable”), controla la información producto del manejo de nuestros Programas de Cómputo siempre que salga de su dispositivo y la entregue al Responsable, y será aplicable el presente Aviso de Privacidad pues de lo contrario, el resguardo y uso de sus Datos Personales es de exclusiva Responsabilidad del Titular; por lo tanto, este se compromete al tratamiento de los Datos Personales de conformidad con el presente documento y la legislación vigente, y tiene su domicilio en México, mismo que es señalado para oír y recibir notificaciones.</p>
          <p>Para tales efectos los Datos Personales que serán sometidos a su tratamiento son enunciados a continuación dependiendo de qué Programa de cómputo esté siendo utilizado por el Titular, en lo sucesivo referidos como el “Programa” y siempre que salgan del Dispositivo del titular para ser enviados a los servidores del Responsable:</p>
          <p>Datos Recabados:</p>
          <p>Programa al que Aplica:</p>
          <p>Datos de identificación:</p>
          <p>I. Nombre completo y Apellidos;</p>
          <p>II. Registro Federal de Contribuyentes (RFC) personal y/o de la empresa de la que es propietario;</p>
          <p>III. Nombre o Razón Social de la empresa de la que es propietario o para la que trabaja;</p>
          <p>IV. Régimen Fiscal de la empresa de la que es propietario el Titular o bien de el mismo;</p>
          <p>V. Departamentos o áreas de la Empresa de la que es propietario;</p>
          <p>VI. Turnos que se manejan en la Empresa de la que es propietario;</p>
          <p>VII. Registro Patronal del Titular o de la Empresa de la que es propietario el Titular;</p>
          <p>X. Tamaño de la Empresa de la que es Propietario;</p>
          <p>Datos de identificación de los empleados del Titular:</p>
          <p>I. Nombre de los Empleados, así como la Oficina en la que trabajan, su puesto, su turno laboral, y correo electrónico;</p>
          <p>II. Lugar y Fecha de Nacimiento del Empleado del Titular;</p>          
          <p>Datos obtenidos a través de medios digitales:</p>
          <p>IV. Access Token</p>
          <p>V. Refresh Token</p>
          <p>VI. Clientid</p>
          <p>Información sobre su ubicación:</p>
          <p>I. Dirección IP;</p>
          <p>II. Ciudad;</p>
          <p>III. Estado;</p>
          <p>IV. País;</p>
          <p>V. Código Postal.</p>
          <p>XV. Almacenes, sus entradas y salidas;</p>
          
          <p>XXII. Datos generales de agentes;</p>
          <p>Datos de contacto:</p>
          <p>I. Correo Electrónico personal y/o del trabajo;</p>
          <p>II. Número Telefónico de su domicilio y/o del trabajo;</p>
          <p>III. Y/o Número de Teléfono Móvil;</p>
          <p>IV. Domicilio personal y/o del trabajo y/o de los Centros de Trabajo;</p>
          <p>Datos de Inventario:</p>          
          <p>·  Existencias;</p>
          <p>Todos los Programas señalados en el cuadro que antecede recaban un Token* de autenticación derivado del procedo de Autenticación Multifactor “OAuth 2.0”, mismo que es vital para el funcionamiento de los mismos, información que es señalada como Dato Sensible y que será tratada en los términos que marca la norma vigente y de la cláusula segunda del presente aviso de privacidad.</p>
          <p>II. DATOS SENSIBLES.</p>
          <p>En caso de que el Responsable recabe y trate datos sensibles (los identificados arriba con asterisco*) del Titular y otros datos necesarios o convenientes para los fines del tratamiento arriba indicados, se lo hará saber previamente al Titular. En todo caso, los datos personales sensibles serán mantenidos y tratados con estricta seguridad y confidencialidad para fines relacionados conforme al presente Aviso de Privacidad y la legislación, reglamentos y normatividad mexicana aplicable y el derecho internacional.</p>
          <p>III. OBJETO Y PROTECCION DE LA INFORMACIÓN.</p>
          <p>El objeto del presente Aviso de Privacidad es definir las condiciones en las que los Datos Personales del Titular serán sometidos a tratamiento por cuenta de la Empresa Responsable. En el marco de sus relaciones contractuales, las partes se comprometen a respetar la normativa vigente aplicable al tratamiento de datos de carácter personal y, en general, a la legislación, reglamentos y normatividad mexicana aplicable y el derecho internacional.</p>
          <p>MiVigilancia en su calidad de Responsable informa al Titular que la información señalada en la cláusula primera del presente aviso de privacidad cuenta con los siguientes métodos de encriptación utilizados de conformidad al listado siguiente:</p>
          <p>Programa:</p>
          <p>Método(s) de encriptación aplicado:</p>
          <p>Datos en los cuales es aplicado:</p>
          <p>·  “SHA-512” algoritmo de hash seguro de 512 bits.</p>
          <p>·  “Rijndael” Advanced Encryption Standard</p>
          <p>·  Clientid</p>
          <p>·  Access Token</p>
          <p>·  Refresh Token</p>
          <p>·  “AES Crypt” solución de cifrado de código abierto multiplataforma.</p>
          <p>·  Clientid</p>
          <p>·  Access Token</p>
          <p>·  Refresh Token</p>
          <p>·  “SHA-256” algoritmo de hash seguro de 256 bits.</p>
          <p>·  Datos de Login</p>
          <p>Referente a los demás datos señalados en la cláusula primera se hace del conocimiento del Titular que no se cuenta con un proceso de cifrado ya que la protección de dicha información depende netamente del Titular y sus Usuarios por la naturaleza de los programas desktop.</p>

        <p>IV. LA DURACIÓN DEL AVISO.</p>
        <p>El presente Aviso de Privacidad entrará en vigor a partir del momento en que el Titular de los Datos Personales otorga su consentimiento por cualquier medio, incluyendo los electrónicos, del uso y tratamiento de sus datos personales, y hasta en tanto no ejerza su derecho de revocación, cancelación o de oposición sobre los mismos.</p>
        <p>Conforme al artículo 25 de la Ley Federal de Protección de Datos Personales en Posesión de los Particulares y 37 de su Reglamento, los plazos de conservación de los datos personales no deberán exceder aquéllos que sean necesarios para el cumplimiento de las finalidades que justificaron el tratamiento y que más adelante se señalan, tomando en cuenta los aspectos administrativos, contables, fiscales, jurídicos e históricos de la información. Una vez cumplida la o las finalidades del tratamiento, y cuando no exista disposición legal o reglamentaria que establezca lo contrario, el Responsable deberá proceder a la cancelación de los datos en su posesión previo bloqueo de estos, para su posterior supresión.</p>
        <p>Ahora bien, si el Titular de los Datos Personales es residente en cualquiera de los países que conforman la Unión Europea, conforme al artículo 6° fracción 1 del REGLAMENTO (UE) 2016/679 DEL PARLAMENTO EUROPEO Y DEL CONSEJO de 27 de abril de 2016 con entrada en vigor el día 25 de Mayo de 2018, el presente Aviso de Privacidad entrará en vigor a partir de la fecha de uso de los programas de cómputo amparado por la licencia de uso celebrada entre el Responsable del tratamiento y el Titular de los Datos Personales que serán sometidos a su tratamiento y por la duración de dicha licencia.</p>
        <p>V. LA NATURALEZA Y FINALIDADES DEL TRATAMIENTO DE DATOS.</p>
        <p>Finalidades de los Datos Recabados:</p>
        <p>Sitio o Herramienta al que Aplica:</p>
        <p>·  Finalidades Primarias:</p>
        <p>·  Verificar, confirmar y crear un identificador para validar su identidad.</p>
        <p>·  Darlo de alta como usuario de cada Programa instalado en el Dispositivo del Titular del que sea requerida el envío de Datos Personales hacia los Servidores del responsable.</p>
        <p>·  Para administrar, operar y dar seguimiento a los servicios y productos que solicita o contrata con nosotros.</p>
        <p>·  Evaluar la calidad del servicio brindado.</p>
        <p>·  De igual manera para completar pedidos y solicitudes de productos, servicios o información.</p>
        <p>·  Para procesar devoluciones y reemplazos</p>
        <p>·  ·Administrar las cuentas de cada Programa instalado en el Dispositivo del Titular del que sea requerida el envío de Datos Personales hacia los Servidores del responsable.</p>
        <p>·  Para respaldar su información.</p>
        <p>·  Revisar la compatibilidad del dispositivo del titular previo a la instalación de los programas de cómputo en escritorio.</p>
        <p>·  Realizar el timbrado y descarga de documentos digitales desde y para su contabilidad.</p>
        <p>·  Timbrado de nómina.</p>
        <p>Finalidades Secundarias:</p>
        <p>·  Enviarle mensajes promocionales, mercadológicos, publicitarios o de prospección comercial de productos y servicios de la línea</p>
        <p>·  Informarle sobre nuevos productos o servicios de la líneay cambios en los mismos.</p>
        <p>·  Comunicar e invitarlo a participar en eventos especiales y encuestas.</p>
        <p>·  Para fines estadísticos y de analítica predictiva, para ofrecerle mejores productos y servicios, además de ofrecerle herramientas que le sirvan en la toma de decisiones.</p>
        <p>·  Para generar telemetría, y modelos de conocimiento y herramientas estadístico-descriptivas y analítico-predictivas y benchmarking, aplicando técnicas de inteligencia artificial, basados en el procesamiento de:</p>
        <p>·  datos obtenidos directamente de la gestión contable, financiera, del flujo de efectivo y bancario, fiscal, y administrativa, y de sus procesos asociados.</p>
        <p>·  datos obtenidos de la gestión del sistema de ventas.</p>
        <p>·  datos obtenidos de los sistemas de recursos humanos y nóminas.</p>
        <p>·  datos del sistema de compras.</p>
        <p>·  datos obtenidos del sistema de planeación de recursos de manufactura.</p>
        <p>·  datos obtenidos de los procesos y de los sistemas de la gestión empresarial para calcular sus indicadores y hacer análisis del comportamiento observado históricamente para hacer predicciones futuras, e identificar aquellas correlaciones y factores más relevantes que inciden en un resultado.</p>
        <p>Todos los anteriores datos, obtenidos de programas comercializados por</p>
        <p>Autorizo el tratamiento de mis datos personales para el desarrollo de las Finalidades Secundarias descritas arriba.</p>
        <p>VI. TRANSFERENCIAS DE DATOS:</p>
        <p>Para darle los servicios de las plataformas tecnológicas disponibles para cada Programa de Cómputo instalado en el Dispositivo del Titular del que sea requerida el envío de Datos Personales hacia los Servidores del Responsable y es necesario compartir sus datos con:</p>
        <p>1. Proveedores de plataformas tecnológicas, esto ocurre tanto para las finalidades primarias como secundarias.</p>
        <p>2. Terceros proveedores de servicios para dar cumplimiento a los Términos y Condiciones de las plataformas, tales como gestionar pagos y el envío de mercancías.</p>
        <p>3. Proveedores de servicios en la nube para almacenar la información.</p>
        <p>-Paybook, INC; comercialmente conocida como Syncfy: Para recopilar los datos del Usuario Final de las instituciones financieras. Al utilizar nuestro servicio, usted (i) otorga a nuestro proveedor externo el derecho, el poder y la autoridad para actuar en su nombre para acceder y transmitir su información personal y financiera de la institución financiera correspondiente de acuerdo con los términos de la política de privacidad de nuestro proveedor externo y (ii) acepta proporcionar información actualizada y precisa sobre su (s) cuenta (s) e identidad de acuerdo con todas las leyes, reglas y regulaciones aplicables.</p>
        <p>Las transferencias antes citadas son tanto nacionales como extranjeros y son necesarias para la prestación de servicios de cada Programa instalado en el Dispositivo del Titular del que sea requerida el envío de Datos Personales hacia los Servidores del Responsable , es decir, son necesarias para el mantenimiento o cumplimiento entre el responsable y el titular, y se realizan conforme a lo dispuesto en el artículo 37 de la Ley Federal de Protección de Datos Personales en Posesión de los Particulares y no requieren de su consentimiento. Cualquier otra transferencia de datos personales sólo será llevada a cabo con tu consentimiento y previa información al respecto.</p>
        <p>VII. LAS OPCIONES Y MEDIOS PARA LIMITAR EL USO O DIVULGACIÓN DE LOS DATOS.</p>
        <p>El tratamiento de sus datos se realiza bajo estándares de seguridad y confidencialidad. Por otro lado, se le informa que, en caso de querer revocar su consentimiento respecto al tratamiento de sus Datos Personales, a través de los siguientes medios:</p>
        <p>·  Usted podrá enviar su solicitud al correo electrónico proteccion.datos@mivigilancia.com, en dicho correo atenderemos y resolveremos su petición conforme a la Ley;</p>
        <p>·  O bien presentar un escrito en el domicilio ubicado en Avenida Lázaro Cárdenas número 3469, Piso 9, Colonia Jardines de los Arcos, Código Postal 44500, Guadalajara, Jalisco, México, dirigido en atención al Encargado del Departamento del Tratamiento de Datos Personales del Responsable, en donde también atenderemos su petición conforme a normas legales vigentes.</p>
        <p>En ambos casos, procedimiento para la revocación del consentimiento, será el mismo que el establecido en el apartado VIII, para el ejercicio de los derechos ARCO.</p>
        <p>La revocación únicamente es aplicable para aquellas finalidades que no son necesarias para el cumplimiento de las obligaciones derivadas del uso de cada Programa instalado en el Dispositivo del Titular del que sea requerida el envío de Datos Personales hacia los Servidores del Responsable.</p>
        <p>VIII. LOS MEDIOS PARA EJERCER LOS DERECHOS DE ACCESO, RECTIFICACIÓN, CANCELACIÓN U OPOSICIÓN, (DERECHOS ARCO).</p>
        <p>Para el ejercicio de sus derechos ARCO y/o modificación y/o la revocación de su consentimiento para el tratamiento de sus datos personales, en caso de que estos sean inexactos o incompletos u oponerse a su tratamiento para ciertos fines, favor de presentar una solicitud por escrito (Solicitud de ejercicio de derechos ARCO) dirigida al Responsable de DATOS PERSONALES a la dirección electrónica o física anotada en el punto V que antecede, y que deberá contener la siguiente información:</p>
        <p>a) Nombre del titular.</p>
        <p>b) Domicilio del titular o dirección de correo electrónico para comunicar respuesta a su solicitud.</p>
        <p>c) Documentos que acrediten la identidad o autorización para representarlo en la solicitud.</p>
        <p>d) Descripción de datos personales sobre los que se pretende ejercer algún derecho ARCO.</p>
        <p>e) Cualquier otro elemento que permita la localización de los datos personales y atención a la solicitud.</p>
        <p>En un plazo máximo de 20 días hábiles contados desde la fecha en que se reciba su solicitud, le comunicará la resolución correspondiente.</p>
        <p>Si  resuelve que su solicitud es procedente, se hará efectiva dentro de los 15 días hábiles siguientes a la fecha en que se le comunicó la resolución.</p>
        <p>Los periodos de comunicación de la resolución en el que se haga efectiva su solicitud podrán ampliarse una sola vez por un periodo igual, siempre y cuando así los justifiquen las circunstancias.</p>
        <p>Si la solicitud resulta procedente, el Responsable de DATOS PERSONALES solicitará al área correspondiente que se lleven a cabo las acciones y cumplimiento de la resolución a favor del titular de los datos personales, dentro de los quince días siguientes a la fecha en que se comunica la respuesta.</p>
        <p>Su solicitud comenzará a computarse a partir del día que la hemos recibido, siempre que se encuentre en nuestro horario de atención, el cual es de 8:00 am a 6:00 pm de lunes a viernes. Si su solicitud es recibida fuera de horario laboral o en día feriado, su solicitud será atendida al siguiente día hábil.</p>
        <p>IX. CAMBIOS AL AVISO DE PRIVACIDAD.</p>
        <p>El Responsable, se reserva el derecho de efectuar en cualquier momento, modificaciones al presente Aviso de Privacidad para cumplir con actualizaciones legislativas, jurisprudenciales, políticas internas, nuevos requisitos para descarga de las licencias y/o la presentación de servicios, en el entendido de que toda modificación al mismo se le dará a conocer por medio del envío de paquetes .txt, mismo que también podrá ser visualizado directamente desde el menú de ayuda del Programa; Archivo que acompañarán a la Licencia de uso o su actualización, de cada Programa instalado en el Dispositivo del Titular del que sea requerida el envío de Datos Personales hacia los Servidores del Responsable.</p>
        <p>X. WEB BANNER y OTRAS TECNOLOGÍAS.</p>
        <p>Los sitios de Internet y servicios en línea del Responsable se basan en el manejo de Web Banner imágenes insertadas en páginas o portales de Internet, así como en correos electrónicos y que entre otros pueden almacenar información de la dirección IP del usuario, tiempo de interacción en los sitios web, entre otros, que permiten al Responsable recabe automáticamente datos personales.</p>
        <p>Se hace del conocimiento del Titular que, al ingresar y continuar con el uso del Sitio de Internet, consiente que el Responsable recabe y trate sus datos personales. El uso de estas tecnologías es necesario por motivos de operación, técnicos y de seguridad.</p>
        <p>El Responsable tratará los Datos Personales del Titular, única y exclusivamente conforme a los términos del presente Aviso de Privacidad que dan origen a la relación entre el Responsable y el Titular de los Datos Personales.</p>
        <p>Otras Tecnologías</p>
        <p>·  Se hace uso de una tecnología conocida como application insights que trata la siguiente información: Tasas de solicitud, tiempos de respuesta y tasas de error - Averigüe qué páginas que son las más conocidas, en qué momento del día y dónde están los usuarios. Vea qué páginas presentan mejor rendimiento. Si los tiempos de respuesta y las tasas de error aumentan cuando hay más solicitudes, quizás tiene un problema de recursos.</p>
        <p>·  Tasas de dependencia, tiempos de respuesta y tasa de error - Averigüe si los servicios externos le ralentizan.</p>
        <p>·  Excepciones: - Analice las estadísticas agregadas o seleccione instancias concretas y profundice en el seguimiento de la pila y las solicitudes relacionadas. Se notifican tanto las excepciones de servidor como las de explorador.</p>
        <p>·  Vistas de página y rendimiento de carga - Notificados por los exploradores de los usuarios.</p>
        <p>·  Llamadas AJAX desde páginas web - Tasas, tiempos de respuesta y tasas de error.</p>
        <p>·  Número de usuarios y sesiones.</p>
        <p>·  Contadores de rendimiento de las máquinas de servidor de Windows o Linux, como CPU, memoria y uso de la red.</p>
        <p>·  Diagnóstico de host de Docker o Azure.</p>
        <p>·  Registros de seguimiento de diagnóstico de la aplicación - De esta forma puede correlacionar eventos de seguimiento con las solicitudes.</p>
        <p>·  Métricas y eventos personalizados que usted mismo escribe en el código de cliente o servidor para realizar un seguimiento de eventos empresariales, como artículos vendidos o partidas ganadas.</p>
        <p>Otras Tecnologías[1]:</p>
        <p>·  Se hace uso de una tecnología conocida como application insights que trata la siguiente información: Tasas de solicitud, tiempos de respuesta y tasas de error - Averigüe qué páginas que son las más conocidas, en qué momento del día y dónde están los usuarios. Vea qué páginas presentan mejor rendimiento. Si los tiempos de respuesta y las tasas de error aumentan cuando hay más solicitudes, quizás tiene un problema de recursos.</p>
        <p>XI. COOKIES</p>
        <p>El Responsable en nuestros sistemas de Escritorio no utiliza cookies para la recolección de Datos Personales.</p>
        <p>XII. DERECHO DE INFORMACIÓN DE LOS INTERESADOS.</p>
        <p>Se le informa al titular de datos personales sobre el derecho que le asiste para acudir al Instituto Nacional de Transparencia, Acceso a la Información y Protección de Datos Personales en caso de considerar que su derecho a la protección de datos personales ha sido vulnerado.</p>
        <p>Corresponde al Responsable del tratamiento de datos personales, facilitar la información a las personas afectadas por las operaciones de tratamiento, en el momento en que tenga conocimiento del daño, pérdida, alteración, destrucción o el uso, acceso o tratamiento no autorizado de los datos personales de la persona que corresponda, para lo cual el Responsable deberá adoptar y tomar las medidas necesarias de seguridad, administrativas, técnicas y físicas, con el fin de proteger y resguardar la información.</p>
        <p>Usted puede inscribirse ante el Registro Público para Evitar Publicidad (REPEP) para limitar el uso y divulgación de sus datos personales, puede obtener más información en el sitio: https://repep.profeco.gob.mx/</p>
        <p>XIII. DESTINO FINAL DE LOS DATOS.</p>
        <p>Todo tratamiento de datos personales estará sujeto al consentimiento de su titular, salvo las excepciones establecidas en la Ley de la materia y su reglamento. Advertimos que de conformidad con el Artículo 8° de la Ley Federal de Protección de Datos Personales en Posesión de los Particulares vigente, el titular consiente tácitamente el tratamiento de sus datos personales, si no manifiesta su oposición.</p>
        <p>Una vez cumplida la o las finalidades del tratamiento, y habiendo procedido a la cancelación de la información que contenga datos personales, el Responsable procederá a eliminar, borrar o destruir dicha información bajo medidas de seguridad previstas por el “Responsable” y conforme a la Ley de la materia.</p>
        <p>Fecha última actualización 11/02/2025</p>
      </div>
      <Footer
        privacyLink="Politica de Privacidad"
        termsLink="Terminos de Servicio"
        cookiesLink="Cookies"
        socialLinkTitleCategory="Siguenos"
        link1="Servicios"
        link2="Precios"
        link3="Registrate"
        link4="Contactanos"
      ></Footer>
      </div>
    );
};
export default privacidadPage;
