import React, { useState } from 'react';
import './verify.css';
import { Helmet } from 'react-helmet';
import Navbar from '../components/navbar';
import Footer from '../components/footer';
import axios from "axios";
import { globalConstants } from '../components/constants';


const recaptchaRef = React.createRef();

const Verify = () => {
    const urlParams = new URLSearchParams(window.location.search);
    const email = urlParams.get('email');
    const [block,setBlock]=useState(email?false:true);
    const [infoMessage,setInfoMessage]=useState('');
    const [seconds, setSeconds] = useState(5);
    const [isLoading, setIsLoading] = useState(false);
    const [formData, setFormData] = useState({
        email: email || '',
        verificationCode: '',
    });

    const tick = () => {
        if (seconds > 0) {
            setSeconds(seconds - 1);
        }
    };

    React.useEffect(() => {
        const interval = setInterval(() => tick(), 1000);
        return () => {
            clearInterval(interval);
        };
    }, [seconds]);

    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormData({
            ...formData,
            [name]: value
        });
    };

    const sendVerificationCode = (e) => {     
        setInfoMessage('');
        setSeconds(300);
        setIsLoading(true); 
        e.preventDefault();
        
        const api = axios.create({
            baseURL: globalConstants.API,
            headers: {
                "Content-Type": "application/json"
            },
        });
        api.post("api/ResendCode", formData).then((response) => {    
            setInfoMessage('Se ha enviado un correo electronico con el código de verificación');
            setIsLoading(false);            
        }   
        ).catch((error) => {       
            setInfoMessage(error.response.data.message);            
            if(error.status==409){                
                window.location.href = "/login";
            }            
            if(error.status==401 || error.status==417)
                setBlock(true);  
            setSeconds(180);
            setIsLoading(false);
        });            
    };

    const handleSubmit = (e) => {     
        setInfoMessage('');
        setIsLoading(true); 
        e.preventDefault();
        
        const api = axios.create({
            baseURL: globalConstants.API,
            headers: {
                "Content-Type": "application/json"
            },
        });
        api.post("api/Verify", formData).then((response) => {    
            alert('Tu cuenta ha sido verificada, serás redirigido a la página de inicio de sesión');
            window.location.href = "/login";
            setIsLoading(false); 
        }   
        ).catch((error) => {
            if(error.status==401 || error.status==417)
                setBlock(true);  
                
            setInfoMessage(error.response.data.message);
            console.log(error); 
            setIsLoading(false);
        });            
        // Add form submission logic here
        console.log('Form submitted', formData);
    };

    return (
        <div className="body-contain">
              <Helmet>
                  <title>Mi Vigilancia Verifica tu cuenta</title>
                 </Helmet>
      <Navbar
        link1="Inicio"
        link2="Acerca"
        link3="Servicios"
        link4="Precios"
        link5="Contacto"
        action1="Register"
        action2="Login"
      ></Navbar>
            <h2>Verifica tu correo</h2>
            <form onSubmit={handleSubmit}>
                <div>
                    <label>Codigo de Seguridad:</label>
                    <br/>
                    <input
                    className='verification-code'
                        type="text"
                        name="verificationCode"
                        value={formData.verificationCode}
                        onChange={handleChange}
                        placeholder='000000'
                        required
                        maxLength={6}
                        onKeyDown ={(event) => {
                            if (!/[0-9]/.test(event.key) && event.key !== 'Backspace' && event.key !== 'Delete') {
                                event.preventDefault();
                            }
                        }}
                    />
                </div>                
                <div className='register-terms'>    
                {block==false &&                
                    <label htmlFor="terms" > Se ha enviado un correo electronio a {email} con el código de verificación, si no encuentras el correo podras volver a renviar en {seconds} segundos.                                
                    </label>    }     
                    <br/>           
                    {seconds === 0 && block==false &&
                    <button className='resend-button' type="button" onClick={sendVerificationCode} >
                    <span role="img" aria-label="email">📧</span> Re-enviar codigó de verificación</button>}
                
                
                </div>    {block==false &&      
                <button className='register-button' type="submit" disabled={isLoading}>
                    {isLoading ? 'Cargando...' : 'Confirmar'}
                </button>
                }
                <label className='info-message'>{infoMessage}.</label>
            </form>
             <Footer
                    privacyLink="Politica de Privacidad"
                    termsLink="Terminos de Servicio"
                    cookiesLink="Cookies"
                    socialLinkTitleCategory="Siguenos"
                    link1="Servicios"
                    link2="Precios"
                    link3="Registrate"
                    link4="Contactanos"
                  ></Footer>
        </div>
    );
};

export default Verify;