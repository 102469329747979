import React, { Fragment } from 'react'
import { CardPayment, initMercadoPago, Payment } from '@mercadopago/sdk-react';
import PropTypes from 'prop-types'
import { globalConstants } from './constants';
import axios from 'axios';


initMercadoPago("TEST-6c7d0db5-22cd-40f3-ab72-a48573a1b1a5", { locale: 'es-MX' });

const CreateSusbcription = (props) => {
const planes=['2c93808494efc59f0194f65936c4042a','2c93808494efc59f0194f65936c4042a','2c93808494efc59f0194f65936c4042a'];//Basico, Negocio , Empresarial
const amount=[globalConstants.basePlanPrice,globalConstants.bussinessPlanPrice,globalConstants.empresarialPlanPrice];//Basico, Negocio , Empresarial

const [error, setError] = React.useState('');

    const initialization = {
        amount: amount[props.preapprovalPlanID],

        payer: {
                email: props.email,
              },
      };
    
      const customization = {
        paymentMethods: {
          maxInstallments: 1,
          creditCard: 'all',
          debitCard: 'all',
        },
      };

      const processPayment = async (payment) => {        
        console.log(payment);
        const api = axios.create({
          baseURL: globalConstants.API,
          headers: {
              "Content-Type": "application/json"
          },
        });
      api.post("api/payment", {
        preapprovalPlanID: planes[props.preapprovalPlanID],
        paymentData: payment,
      }).then((response) => {    
          props.confirmPayment();  
      }   
      ).catch((error) => {
          setError('Ocurrio un error al procesar su pago, por favor intentelo de nuevo o contacte a soporte@mivigilancia.com');
      });            
      
      };

  return (
    <div>
        
        <label style={{color:"red"}}>{error}</label>
       <CardPayment
          initialization={initialization}
          customization={customization}
          onSubmit={processPayment}
        />
    </div>
  )
}

CreateSusbcription.defaultProps = {
  email: 'Medium length heading goes here',
  preapprovalPlanID: 0,
}

CreateSusbcription.propTypes = {
  email: PropTypes.string,
  preapprovalPlanID: PropTypes.number,
}

export default CreateSusbcription
