import React from 'react'
import ReactDOM from 'react-dom'
import { Route,   Switch,   Redirect,   BrowserRouter,Outlet } from 'react-router-dom'

import './style.css'
import NotFound1 from './views/not-found'
import Home from './views/home'
import Register from './views/register'
import Login from './views/login'
import condicionesPage from './views/condiciones'
import ScrollToTop  from "./components/scrollToTop";
import Verify from './views/verify'
import { AuthProvider } from './AuthContext'
import PrivateRoute from './PrivateRoute'
import dashboardPage from './views/dashboard'
import privacidadPage from './views/privacidad'
import setupPage from './views/setup'


const App = () => {

  return (
    <AuthProvider>
    <BrowserRouter>          
 
    <ScrollToTop />
      <Switch>             
        <Route component={NotFound1} exact path="/not-found" />
        <Route component={Home} exact path="/" />
        <Route component={Register} exact path="/register" />
        <Route component={Verify} exact path="/verify" />
        
        <Route component={Login} exact path="/login" />        
        <Route component={privacidadPage} exact path="/privacidad" />
        <Route component={condicionesPage} exact path="/condiciones" />
        <PrivateRoute component={dashboardPage} path="/dashboard" />
        <PrivateRoute component={setupPage} path="/setup" />
        <Route component={NotFound1} path="**" />
        <Redirect to="**" />
      </Switch>
    </BrowserRouter>
    </AuthProvider>
  )
}
ReactDOM.render(<App />, document.getElementById('app'))

