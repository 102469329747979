import React, { useState } from 'react';
import './setup.css';

import { Helmet } from 'react-helmet';
import Footer from '../components/footer';
import NavbarAuth from '../components/navVarAuth';
import CreateSusbcription from '../components/CreateSubscription';
import axios from 'axios';
import { globalConstants } from '../components/constants';

const setupPage = () => {

    const [isloading, setIsLoading] = useState(false);
    const [planID,setPlanID] = useState(0);
    const [error, setError] = useState('');
    const [step, setStep] = useState(2);
     const [formData, setFormData] = useState({
            correoContacto: sessionStorage.getItem('user'),
            nombreContacto: '',
            telefonoContacto: '',
            nombreEmpresa:'',
            direccion:'',
            razonSocial:'',
            
        });

        const handleChange = (e) => {
            setError('');
            const { name, value } = e.target;
            setFormData({
                ...formData,
                [name]: value
            });
        };
    
        const handleSubmit = (e) => {
            e.preventDefault();
    
            setIsLoading(true);
                    
            const api = axios.create({
                baseURL: globalConstants.API,
                headers: {
                    "Content-Type": "application/json"
                },
            });
            api.post("api/Register", formData).then((response) => {    
                setIsLoading(false);
                setStep(2);          
            }   
            ).catch((error) => {
                setIsLoading(false);                
                alert("Error al registrar el usuario, verifique su información e intente de nuevo");
                console.log(error); 
            });            
        };
  
    return (
        <div className='body-contain'>
             <Helmet><title>Mi Vigilancia Configurar tu cuenta</title></Helmet>
            <NavbarAuth></NavbarAuth>
           

            <div className='container'>
              
                {error && <div className='error'>{error}</div>}
             
{step==1 && <div>
    <h1> Configura tu cuenta</h1>                      
    <p>Para continuar necesitamos información adicional de tu empresa:</p>
                <form onSubmit={handleSubmit}>

                
            
                <div>
                
                    <label>Nombre:</label>
                    <input
                        type="text"
                        name="nombreContacto"
                        value={formData.nombreContacto}
                        onChange={handleChange}
                        required
                    />
                </div>
                <div>
                    <label>Telefono:</label>
                    
                    <input
                        type="text"
                        name="telefonoContacto"
                        value={formData.telefonoContacto}
                        onChange={handleChange}
                        required
                    />
                                       
                </div>
                <div>
                    <label>Nombre de la Empresa:</label>
                    <input
                        type="text"
                        name="nombreEmpresa"
                        value={formData.nombreEmpresa}
                        onChange={handleChange}
                        required
                    />
                </div>
                <br/>
                <br/>
                <button className='register-button' type="submit" disabled={isloading} >
                {isloading ? 'Actualizando...' : 'Actualizar y continuar al pago'}
                </button>                
            </form>
            </div>}
            {step==2  && <div>
                <h1> Selecciona el Plan que deseas contratar</h1>                      
                <p onClick={()=>setPlanID(0)} className={planID==0?'selected-plan':'base-plan'}><input checked={planID==0} type='radio'></input> Plan Basico: ${globalConstants.basePlanPrice} al mes</p>
                <p onClick={()=>setPlanID(1)} className={planID==1?'selected-plan':'base-plan'}><input checked={planID==1} type='radio'></input> Plan Negocio: ${globalConstants.bussinessPlanPrice} al mes</p>
                <p onClick={()=>setPlanID(2)} className={planID==2?'selected-plan':'base-plan'}><input checked={planID==2} type='radio'></input> Plan Empresarial: ${globalConstants.empresarialPlanPrice} al mes</p>
                <button className='register-button' onClick={()=>setStep(3)} > Continuar al pago</button>
            </div>}
            {step==3  && 
                <div>
                <h1> Información de Pago</h1>    
                    <CreateSusbcription email={formData.correoContacto} preapprovalPlanID={planID} confirmPayment={()=>setStep(4)}></CreateSusbcription>
            </div>}
            {step==4  && 
                <div>
                <h1> Gracias por registrarse </h1>    
                <p> Tu cuenta ha sido registrada con éxito, ya puedes empezar a utilizar el sistema Mi Vigilancia para ayudarte a optimizar 
                la gestión de tu equipo de seguridad con una sola plataforma.</p>
                <p>&nbsp; </p>
                
                <p> Si tienes alguna pregunta o necesitas ayuda, no dudes en contactarnos escribiendo a
                    soporte@mivigilancia.com</p>
                    <p>&nbsp; </p>
                <button className='register-button' onClick={()=>window.location.href='/dashboard'} > Ir a administrar usuarios</button>
            </div>}
            </div>         
        </div>
    );
};

export default setupPage;
